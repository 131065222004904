import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import ShoppingExtSuccessTemplate from '../../templates/shoppingExtSuccess';
import yahooShoppingExtensionLogoDataUrl from './images/yahoo-shopping-extension-logo.svg';
import illustration1DataUrl from './images/marketing-illustration-1.svg';
import illustration2DataUrl from './images/marketing-illustration-2.svg';
import illustration3DataUrl from './images/marketing-illustration-3.svg';
import mailIconDataUrl from './images/mail.svg';
import faviconDataUrl from './images/favicon.svg';

export default function Card(props) {
    const { ncid, onCookieLookupDidComplete } = props;
    const { content } = useStaticQuery(query);
    const iconsDataUrlDictionary = {
        faviconDataUrl,
        mailIconDataUrl
    };
    const imagesDataUrlDictionary = {
        yahooShoppingExtensionLogoDataUrl,
        illustration1DataUrl,
        illustration2DataUrl,
        illustration3DataUrl,
    };

    return (
        <ShoppingExtSuccessTemplate 
            iconDataUrls={iconsDataUrlDictionary}
            imageDataUrls={imagesDataUrlDictionary}
            content={content}
            ncid={ncid}
            onCookieLookupDidComplete={onCookieLookupDidComplete} />
    );
}

export const query = graphql`
    query {
        content: shoppingExtSuccessJson {
            yahoo_shopping_extension_logo_image_accessibility_text
            heading
            illustration1_heading
            illustration1_description
            illustration1_image_accessibility_text
            illustration2_heading
            illustration2_description
            illustration2_image_accessibility_text
            illustration3_heading
            illustration3_description
            illustration3_image_accessibility_text
            primary_button_title
            secondary_button_title
            mail_icon_accessibility_text,
            config {
                rapid {
                    init {
                        spaceid
                        keys {
                            pt
                            pct
                            pg_name
                            ver
                        }
                    }
                }
            }            
        }  
    }
`;
