import React, { useEffect, useRef, useState } from 'react'
import Helmet from 'react-helmet'
import { getRapid } from '../services';
//import Header from '../components/Header'

export default function ShoppingExtSuccessTemplate(props) {
  const { iconDataUrls, imageDataUrls, content, ncid, onCookieLookupDidComplete } = props;
  const { faviconDataUrl, mailIconDataUrl } = iconDataUrls;
  const {
    yahooShoppingExtensionLogoDataUrl,
    illustration1DataUrl,
    illustration2DataUrl,
    illustration3DataUrl
  } = imageDataUrls;
  const {
    yahoo_shopping_extension_logo_image_accessibility_text,
    heading,
    illustration1_heading,
    illustration1_description,
    illustration1_image_accessibility_text,
    illustration2_heading,
    illustration2_description,
    illustration2_image_accessibility_text,
    illustration3_heading,
    illustration3_description,
    illustration3_image_accessibility_text,
    primary_button_title,
    secondary_button_title,
    mail_icon_accessibility_text
  } = content;
  const rapidConfig = content.config.rapid.init;
  // Add ncid to rapid when available
  if (ncid) {
      rapidConfig.keys.ncid = ncid;
  }
  const wrapperDivRef = useRef(null);
  const [onRapidInit, setOnRapidInit] = useState(false);
  const [rapid, setRapid] = useState({});

  useEffect(() => {
    const wrapperDiv = wrapperDivRef.current

    if (wrapperDiv) {
      wrapperDiv.setAttribute('data-nid', ncid);
    }
  }, [ncid]);

  useEffect(() => {
    // Fire page view only after cookieLookupDidComplete
    if (onCookieLookupDidComplete) {
      const rapidInstance = getRapid(rapidConfig);
      setRapid(rapidInstance);
      setOnRapidInit(true)
    }
  }, [onCookieLookupDidComplete]);

  useEffect(() => {
    const onSignInClickHandler = (e) => {
      const element = e.currentTarget.id;
      if (element) {
        rapid.beaconClick('post-install', 'sign-in', 1, {
          itc: 1,
          elm: 'button',
          elmt: element,
        });
      }
    };

    const onSkipClickHandler = (e) => {
      const element = e.currentTarget.id;
      if (element) {
        rapid.beaconClick('post-install', 'skip', 1, {
          itc: 1,
          elm: 'button',
          elmt: element,
        });
      }
    }
    
    document.getElementById('sign-in-button').addEventListener('click', onSignInClickHandler);
    document.getElementById('skip-button').addEventListener('click', onSkipClickHandler);

    // Cleanup if necessary
    return () => {
      document.getElementById('sign-in-button').removeEventListener('click', onSignInClickHandler);
      document.getElementById('skip-button').removeEventListener('click', onSkipClickHandler);
    };
  }, [onRapidInit]);
  
  return (
    <>
      <Head faviconDataUrl={faviconDataUrl} />
      <div className="bg-color-container"></div>
      <div className="bg-top-color-container"></div>
      <div className="sh-ext-container" ref={wrapperDivRef}>

        <img src={yahooShoppingExtensionLogoDataUrl} alt={yahoo_shopping_extension_logo_image_accessibility_text} className="main-logo" />
        <h1 className="title">
          {heading}
        </h1>

        <div className="card-container">
          <div className="card">
            <div className="card-image">
              <img src={illustration1DataUrl} alt={illustration1_image_accessibility_text}
                className="object-contain" />
            </div>

            <div className="card-content">
              <h2 className="card-title">
                {illustration1_heading}
              </h2>
              <p className="card-text">
                {illustration1_description}
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-image">
              <img src={illustration2DataUrl} alt={illustration2_image_accessibility_text}
                className="object-contain" />
            </div>

            <div className="card-content">
              <h2 className="card-title">
                {illustration2_heading}
              </h2>
              <p className="card-text">
                {illustration2_description}
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-image">
              <img src={illustration3DataUrl}
                alt={{illustration3_image_accessibility_text}} className="object-contain" />
            </div>

            <div className="card-content">
              <h2 className="card-title">
                {illustration3_heading}
              </h2>
              <p className="card-text">
                {illustration3_description}
              </p>
            </div>
          </div>
        </div>

        <button className="sign-in-button" id="sign-in-button" aria-label={primary_button_title}>
          <img src={mailIconDataUrl} className="mail-icon" alt={mail_icon_accessibility_text} aria-hidden="true" />
          <span className="button-text" aria-hidden="true">{primary_button_title}</span>
        </button>

        <div className="skip">
          <a className="skip-link" id="skip-button" href="https://www.yahoo.com/lifestyle/tagged/shopping">{secondary_button_title}</a>
        </div>
      </div>
    </>
  )
}

function Head({ faviconDataUrl }) {
  return (
    <Helmet>
      <title>Yahoo Shopping</title>
      <link rel="icon" type="image/x-icon" href={faviconDataUrl} />
      <style type="text/css">{`  
        @font-face {
          font-family: "Centra No2";
          src: url("https://s.yimg.com/bw/fonts/centra-no2-book.woff2") format("woff2");
          font-weight: 400;
          font-display: swap;
        }

        @font-face {
          font-family: "Yahoo CR4 Beta";
          src: url("https://s.yimg.com/bw/fonts/yahoo-sans-cr4-vf.woff2") format("woff2");
          font-weight: 100 1000;
          font-display: swap;
        }

        /* Base Styles */

        body {
          margin: 0;
          min-height: 100vh;
          position: relative;
        }

        .logo {
          position: absolute;
          top: 45px;
          left: 45px;
          width: 122px;
          height: auto; /* Ensure height scales proportionally */
        }

        .bg-color-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%; 
          background-color: white; /* Set the background color */
        }

        .bg-top-color-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 450px; /* Set the height */
          aspect-ratio: 16 / 9;
          background-color: #7D2EFF; /* Set the background color */
        }

        .sh-ext-container {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 10;
        }

        .main-logo {
          margin-top: 74px;
          width: 323px;
          height: auto; /* Ensure height scales proportionally */
        }

        .title {
          font-family: 'Centra No2', sans-serif;
          height: 57px;
          color: #fff;
          font-size: 48px;
          line-height: 64px;
          margin-top: 11px;
          font-weight: normal;
          text-align: center;
          margin-bottom: 9px;
        }

        .card-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 45px;
          margin-top: 44px;
        }

        .card {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 25vw;
          max-width: 387px;
          border: 0;
          background-color: transparent;
        }

        .card-image img {
          max-width: 100%; /* Makes sure images scale down within their container */
          height: auto; /* Maintain aspect ratio */
        }

        .card-content {
          max-width: 260px;
        }

        .card-title {
          font-family: 'Yahoo CR4 Beta', sans-serif;
          font-weight: 500;
          color: #232A31;
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 0;
          margin-top: 0;
        }

        .card-text {
          font-family: 'Yahoo CR4 Beta', sans-serif;
          font-weight: 400;
          color: #242220;
          font-size: 20px;
          line-height: 27px;
          margin-top: 12px;
        }

        .sign-in-button {
          background-color: #1D2228;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 44px;
          padding-left: 36px;
          color: white;
          border-radius: 9999px;
          min-width: 338px;
          margin-top: 43px;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;
          border: 0;
        }

        .sign-in-button:hover {
          background-color: #5015B0;
        }

        .mail-icon {
          margin-right: 13px;
        }

        .button-text {
          margin-top: 15px;
          margin-bottom: 17px;
          font-family: 'Yahoo CR4 Beta', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }

        .skip {
          margin-top: 28px;
          margin-bottom: 80px;
          padding: 2px;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .skip:hover {
          background-color: #f1f5f9;
        }

        .skip-link {
          color: #232A31;
          text-decoration: underline;
          font-family: 'Yahoo CR4 Beta', sans-serif;
          font-weight: 400;
          font-size: 20px;
          line-height: 22px;
          text-align: center;
          display: block;
        }

        /* Responsive Styles */

        /* Small screens (phones, 600px and down) */
        @media (max-width: 600px) {
          .bg-top-color-container {
            height: 300px;
          }
        }

        /* Medium screens (tablets, 600px to 1024px) */
        @media (min-width: 601px) and (max-width: 1024px) {
          .bg-top-color-container {
            height: 400px;
          }
        }
    `}</style>
    </Helmet>
  )
}